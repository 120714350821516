
import { Modal } from 'bootstrap';
import { defineComponent, inject, ref, watchEffect } from 'vue';
import { Select } from '@/components/input-elements';
import InnerLoader from '@/components/InnerLoader.vue';
import { Apollo, Notify } from '@/core/services';
import { GET_CATEGORIES_DROPDOWN_LIST } from '@/modules/catalog/categories/graphql/Queries';
import { useI18n } from 'vue-i18n';
import { ShopFooterAddFormData } from '@/modules/system/shop-footer/interfaces';
import { CREATE_SHOP_FOOTER } from '@/modules/system/shop-footer/graphql/Mutations';

export default defineComponent({
    name: 'shop footer modal',
    components: {
        Select,
        InnerLoader
    },
    setup() {
        const loader = ref(false);
        const systemLocale = ref();
        const modal: any = ref();
        const categoriesList = ref([]) as Record<any, any>;
        const topCategories = ref([]) as Record<any, any>;
        const i18n = useI18n();
        const emitter: any = inject('emitter');
        const addShopFooterForm = ref<null | HTMLFormElement>(null);
        const addShopFooterData = ref<ShopFooterAddFormData>({
            categories: [],
            status: false
        });

        const submitShopFooterForm = () => {
            addShopFooterForm.value?.validate(async (valid: boolean) => {
                if (valid) {
                    const formData = {
                        category_ids: addShopFooterData.value.categories,
                        status: addShopFooterData.value.status
                    };
                    loader.value = true;
                    await Apollo.mutate({
                        mutation: CREATE_SHOP_FOOTER,
                        variables: {
                            input: formData
                        },
                        update: (store, { data: { create_shop_footer } }) => {
                            loader.value = false;
                            modal.value.hide();
                            emitter.emit('reloadFooter');
                            Notify.success(`${i18n.t('message.RECORD_ADDED_SUCCESSFULLY')}`);
                        }
                    });
                }
            });
        };

        watchEffect(() => {
            systemLocale.value = i18n.fallbackLocale.value;
        });

        const getCategories = () => {
            loader.value = true;
            Apollo.watchQuery({
                query: GET_CATEGORIES_DROPDOWN_LIST,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only'
            }).subscribe(({ data }) => {
                categoriesList.value = [];
                data.categories?.data.forEach(ele => {
                    categoriesList.value.push({
                        label: JSON.parse(ele.name)[`${systemLocale.value}`].name,
                        value: ele.id
                    });
                });
                loader.value = false;
            });
        };
        emitter.on('addShopFooterAction', () => {
            addShopFooterForm.value?.resetFields();
            getCategories();
            modal.value = new Modal(document.getElementById('modal_add_shop_footer'));
            modal.value.show();
        });

        return {
            loader,
            categoriesList,
            topCategories,
            addShopFooterData,
            addShopFooterForm,
            submitShopFooterForm
        };
    }
});
